import { useState } from "react";
import { ErrorIcon, Logo, Spinner } from "./assets/icons";
import axios from "axios";
import { Input, notification, Upload, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import FormModel from "./models/FormModel";

const ENDPOINT_URL =
  process.env.REACT_APP_BACKEND_URL + "EmailAttach/send-email";

const REQUEST_HEADERS: any = {
  "Content-Type": "multipart/form-data",
};

function App() {
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      file: null,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email()
        .matches(
          /\.[a-zA-Z]+$/,
          "Email must end with a period followed by letters (e.g., .com)"
        )
        .required("Email is required"),
      phone: yup
        .string()
        .matches(
          /^(010|011|012|015)\d{8}$/,
          "Phone number must start with 010, 011, 012, or 015 and be 11 digits long"
        )
        .required("Phone number is required"),
    }),
    onSubmit: async (values: FormModel) => {
      if (cv[0] === null || cv[0] === undefined) {
        notification.error({
          message: "Please upload your CV",
        });
        return;
      }

      setLoading(true);

      try {
        const response = await axios.post(
          ENDPOINT_URL,
          {
            Email: values.email,
            Phone: values.phone,
            File: cv[0],
          },
          {
            headers: REQUEST_HEADERS,
          }
        );

        if (response.status === 200) {
          notification.success({
            message: "Your Application Was Sent Successfully",
          });
        }
      } catch (err) {
        notification.error({
          message: "Something went wrong try again",
        });
      } finally {
        setLoading(false);
      }
    },
  });

  const [cv, setCv] = useState<UploadFile[]>([]);

  const [loading, setLoading] = useState(false);

  const props: UploadProps = {
    name: "file",
    onRemove: (file: any) => {
      const index = cv.indexOf(file);
      const newFileList = cv.slice();
      newFileList.splice(index, 1);
      setCv(newFileList);
    },
    beforeUpload: (file: RcFile) => {
      if (cv.length === 1) {
        notification.error({
          message: "You can only place 1 file",
        });
        return false;
      }
      setCv([...cv, file]);
      return false;
    },
    fileList: cv,
  };

  return (
    <div className="w-full h-full min-h-screen flex flex-col items-center justify-center relative overflow-x-hidden">
      <div className="w-[95%] scrn-445:w-[420px] min-h-[500px] scrn-445:min-h-[450px] py-5 shadow-lg bg-white flex flex-col items-center pt-8 gap-y-3 rounded-md">
        <img src={Logo} />
        <p className="text-black text-2xl text-center max-w-[80%] font-semibold">
          Unlock your career potential with Intrn
        </p>
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col items-center px-4 scrn-445:px-12 pt-5 gap-y-3"
        >
          <div className="w-full flex flex-col items-start gap-y-2">
            <Input
              type="email"
              placeholder="Email"
              name="email"
              className="border border-black rounded-lg w-full px-4 !h-[3rem]"
              onChange={formik.handleChange}
            />
            {formik.errors.email && (
              <p className="text-[red] flex items-center gap-x-2">
                <img src={ErrorIcon} alt="error" width={20} height={20} />
                {formik.errors.email}
              </p>
            )}
          </div>
          <div className="w-full flex flex-col items-start gap-y-2">
            <Input
              type="tel"
              placeholder="Phone"
              name="phone"
              className="border border-black rounded-lg w-full px-4 !h-[3rem]"
              onChange={formik.handleChange}
            />
            {formik.errors.phone && (
              <p className="text-[red] flex items-center gap-x-2">
                <img src={ErrorIcon} alt="error" width={20} height={20} />
                {formik.errors.phone}
              </p>
            )}
          </div>
          <div className="w-full flex justify-start">
            <Upload {...props}>
              <button
                type="button"
                className="border border-black rounded-lg !h-[3rem] flex items-center justify-center px-4 py-3 text-black gap-x-4"
              >
                <p>Upload CV</p>
                <UploadOutlined />
              </button>
            </Upload>
          </div>
          <button
            type="submit"
            className="w-full !h-10 rounded-lg bg-[#142644] mt-5 text-white flex items-center justify-center"
          >
            {loading ? (
              <img src={Spinner} alt="spinner" className="w-full h-16" />
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default App;
